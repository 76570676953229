<template>
  <div class="text-xs italic pt-2 font-medium top-banner">
    <div v-if="hasStandardPlan">
      Standard Plan
    </div>
    <div v-else >
      Instant, with free Analytics.
    </div>
  </div>
  <section class="login-container">
    <div v-if="email">
      <!--Something is wrong with vue router: here it doesnt unload the cloud component but loads the acccount comp (hidden) Though the same construct works on account pointing to the deploys/deploy_link-->
      <!--TODO: this is slow tho, so find a fix -->
      <!--<router-link class="underline" :to="{ name: 'Account', params: { email: email }}" >{{email}}</router-link>-->
      <a :href="toRoute">{{email}}</a>
    </div>
    <div v-else>
      <router-link to="/get-access" class="">Login</router-link>
    </div>
  </section>
</template>

<script charset="utf-8">
export default {
  components: {
  },
  props: {
  },
  data: function () {
    return {
      email: null,
    }
  },
  mounted() {
    this.email = window.localStorage.getItem('spinupuseremail')

    this.$nextTick(() => {
      this.$bus.on('authChange', (data) => {
        this.email = window.localStorage.getItem('spinupuseremail')
      })
    })
  },
  methods: {
  },
  computed: {
    toRoute() {
      return 'https://' + location.host + `/account/${this.email}`
    },
    hasStandardPlan() {
      return window.localStorage.getItem('spinupuserplan') === 'standard'
    },
  }
}

</script>

<style type="text/css" media="screen">

.login-container {
    position: fixed;
    right: 10px;
    top: 5px;
}

.top-banner {
    position: fixed;
    left: 6px;
    top: 40px;
}
</style>
