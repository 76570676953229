<template>
  <!--<div><svg width="16" height="16" style="margin-top: 5px" class="spinup-logo checkmark-deploy" viewBox="0 0 16 16" version="1.1" aria-hidden="true"><path fill="#28a745" fill-rule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.78-9.72a.75.75 0 00-1.06-1.06L6.75 9.19 5.28 7.72a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l4.5-4.5z"></path></svg></div>-->
  <div><svg width="16" height="16" style="margin-top: 5px" class="spinup-logo checkmark-deploy" viewBox="0 0 16 16" version="1.1" aria-hidden="true"><path fill="#28a745" fill-rule="evenodd" d="M8 16A8 8 0 108 0a8 8 0 000 16zm3.78-9.72a.75.75 0 00-1.06-1.06L6.75 9.19 5.28 7.72a.75.75 0 00-1.06 1.06l2 2a.75.75 0 001.06 0l4.5-4.5z"></path></svg></div>
  <a href="/" class="spinup-link pl-1 pt-1 font-semibold italic">SpinUp</a>
  <a href="/" class="spinup-distros pl-1 pt-1 font-semibold italic">Distributions</a>
  <router-view  />
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.spinup-link {
    position: fixed;
    left: 23px;
    top: 0;
}

.spinup-distros {
    position: fixed;
    left: 1px;
    top: 20px;
}

.spinup-logo {
    position: fixed;
    left: 0;
    top: 2px;
}
</style>
