<template>
  <div>
    <UploaderPreSignUp @deployed="$emit('deployed', $event)" @deployedLoggedIn="$emit('deployedLoggedIn', $event)" :update='update' :subdomain="subdomain" :customDomain="customDomain" :deploy_link="deployLink" :deploy_type="deploy_type" />
  </div>
</template>

<script>
import UploaderPreSignUp from './file_uploader/UploaderPreSignUp.vue'
import { FeedbackFish } from "@feedback-fish/vue";

export default {
  components: {
    UploaderPreSignUp,
  },
  data: function() {
    return {
    };
  },
  props: {
    subdomain: String,
    customDomain: String,
    deployLink: String,
    deploy_type: String,
    update: [Boolean, null]
  }
};
</script>

<style scoped>
</style>
